// Success.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import animationData from './lotties/tick.json'; // Replace with the path to your Lottie JSON file
import './Success.css';

const Success = ({ keyToken }) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopyKey = () => {
      navigator.clipboard.writeText(keyToken)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // "Copied" disappears after 2 seconds
        })
        .catch(err => console.error('Could not copy text: ', err));
    };

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData, // your imported animation data
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    return (
      <div className="success-page">
        <div className="success-content">
          <div className="success-header">
            Select &gt; Payment &gt; Success
          </div>
          <div className="success-checkmark">
            <Lottie 
              options={defaultOptions}
              height={250}
              width={250}
            />
          </div>
          <div className="success-message">
            Payment successful!
          </div>
          <div className="success-key">
            Your Key:
            <div className="key-token" onClick={handleCopyKey}>
              {keyToken}
              <FontAwesomeIcon icon={faClipboard} className="clipboard-icon" />
            </div>
            {copied && <div className="copy-confirmation">Copied!</div>}
          </div>
          <button className="continue-button">
            Continue
          </button>
        </div>
      </div>
    );
  };
  
  export default Success;