import React from 'react';
import './DiscordVerify.css';

function DiscordVerify() {
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  return (
    <div className="discordverify-page">
      <div className="discordverify-content">
        <h1 className="discordverify-header">Verified!</h1>
        <p className="discordverify-message">Head back to Discord to view channels!</p>
        <div className="discordverify-actions">
          <button
            className="discordverify-button"
            onClick={() => handleRedirect('https://cheats.cx')}>
            Shop
          </button>
          <button
            className="discordverify-button"
            onClick={() => handleRedirect('https://discord.gg/GDvCUdG2zQ')}>
            Discord Server
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscordVerify;
