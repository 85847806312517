import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'; // Import your new home page component
import WyvernApp from './WyvernApp'; // Rename your existing App component to WyvernApp and import it herea
import TwistedApp from './TwistedApp';
import DiscordVerify from './DiscordVerify'; // Import the new DiscordVerify component

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wyvern" element={<WyvernApp />} />
        <Route path="/twisted" element={<TwistedApp />} />
        <Route path="/discordverify" element={<DiscordVerify />} /> {/* New route */}
      </Routes>
    </div>
  );
}

export default App;